<template>
	<v-col>
		<v-layout justify-end
			><v-btn color="primary" @click="backToSearch()" dark
				>返回 Back</v-btn
			></v-layout
		>
		<v-col>
			<v-layout justify-center class="page-title"
				><strong>{{ labelsContent.submissionTitles[action] }}</strong></v-layout
			>
			<v-layout v-if="!!submission" justify-center>
				<v-layout justify-center class="sub-form">
					<v-col>
						<v-card>
							<v-card-text
								><strong>地點編號 Location ID:</strong><br />
								{{ submission.location.id }}</v-card-text
							>
							<v-card-text
								><strong>地址 Address:</strong><br />
								<span
									v-html="
										concatAddr(
											submission.location.address_tc,
											submission.location.address_en,
										)
									"
								></span>
							</v-card-text>
							<v-card-text
								><strong>{{locationTypeLabel}}:</strong>
								<br />{{ submission.location.location_no }}</v-card-text
							>
						</v-card>
						<v-divider black style="margin: 20px 0px 40px 0px;"></v-divider>
						<v-card>
							<v-card-text
								><strong>表格日期 Submission date:</strong><br />
								{{
									formatDate(submission.created_date, 'DD/MM/YYYY')
								}}</v-card-text
							>
							<v-card-text
								><strong>檢查日期 Examination date:</strong><br />
								{{
									formatDate(submission.examination_date, 'DD/MM/YYYY')
								}}</v-card-text
							>
							<v-card-text
								><strong>機器類型 Machine Type:</strong><br />
								{{ selectedMachine.text }}</v-card-text
							>
						</v-card>

						<v-col>
							<v-card
								v-for="(item, index) in selectedMachine.components"
								:key="item.label"
								elevation="0"
								class="form-card"
							>
								<v-card-text class="form-fld-lbl"
									><strong>{{ item.label }}</strong></v-card-text
								>
								<v-img
									v-if="submission.photos[index]"									
									:src="submission.photos[index].url"
									:contain="true"
									max-height="300px" height="300px"
									@click="openNewWin(submission.photos[index].url)"
									style="cursor:pointer;"
								></v-img>
							</v-card>
						</v-col>
						<v-col>
							<v-card>
								<v-card-text
									><strong>備註 Remarks:</strong><br />
									{{ submission.remark }}</v-card-text
								>
							</v-card>
						</v-col>
					</v-col>
				</v-layout>
			</v-layout>
		</v-col>
	</v-col>
</template>

<script>
// import EnginerService from '../service/engineer-service';
import * as submissionLabel from '../config/submission-details.config';

import SubmissionService from '../service/submission-service';
const submissionService = new SubmissionService();
import LoginService from '../service/login-service';
const loginService = new LoginService();

const moment = require('moment-timezone');

export default {
	name: 'SubmissionView',
	props: {
		action: {
			type: String,
		},
		id: {
			type: String,
		},
	},
	data: () => ({
		selectedMachine: {},
		labelsContent: submissionLabel,
		machineTypes: [],
		submission: null,
		submissionLabel: ''
	}),
	methods: {
		formatDate(dateStr, formatStr) {
			return moment
				.utc(dateStr)
				.tz('Asia/Hong_Kong')
				.format(formatStr);
		},
		backToSearch() {
			this.$router.push({ path: '/search' });
		},
		openNewWin(url) {
			window.open(url);
		},
		concatAddr(addr_tc, addr_en) {
			return addr_tc + '<br/>' + addr_en;
		},
		promptSnackBar(msg, color, extraProps = {}) {
			this.$emit('snack-bar', {
				color,
				msg,
				extraProps,
			});
		},
	},
	created() {
		submissionService
			.getSubmission(loginService.getEngineerNo(), this.id)
			.then((res) => {
				if (res.data.success) {
					this.submission = res.data.result;
					if (
						this.submission.location.location_type ==
						submissionLabel.LOCATION_LIFT_TYPE
					) {
						this.machineTypes = this.labelsContent.liftType;

						let machine = this.labelsContent.liftType.find((obj) => {
							return obj.value === this.submission.machine_type;
						});

						this.selectedMachine = machine;
						this.locationTypeLabel = '升降機編號 Lift No.';

						// this.selectedMachine = this.labelsContent.liftType[
						// 	this.submission.machine_type
						// ];
					} else {
						this.machineTypes = this.labelsContent.escalatorType;
						// this.selectedMachine = this.labelsContent.escalatorType[
						// 	this.submission.machine_type
						// ];

						let machine = this.labelsContent.escalatorType.find((obj) => {
							return obj.value === this.submission.machine_type;
						});

						this.selectedMachine = machine;
						this.locationTypeLabel = '扶手電梯編號 Escalator No.'
					}
				} else {
					this.promptSnackBar(
						'Submission not found, please search again.',
						'error',
					);
					//submission not found
					this.$route.push('/search');
				}
			})
			.catch((err) => {
				console.log(err);
				this.promptSnackBar(
					'系統錯誤，請稍後再試！System Error, please try again!',
					'error',
				);
				//submission not found
				this.$route.push('/search');
			});
	},
};
</script>

<style lang="scss">
.sub-ttl {
	font-size: x-large;
	padding-bottom: 1rem;
}

.translate-down {
	transform: translateY(2rem);
}

.form-fld-lbl {
	margin-bottom: -1rem;
	transform: translateX(-1.8rem);
}

.form-card {
	margin-bottom: 20px;
}
</style>
